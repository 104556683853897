@import '../../../../scss/variables';

.navbar {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 5rem;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: $global_primary;
  box-shadow: inset 0px 5px 10px rgba(#000000, 0.5);
  transform: translateX(100%);
  z-index: 999;
  visibility: 0;
  padding: 2rem 0 4rem;
  height: calc(100vh - 5rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .navbar__group {
    padding: 0 2rem;

    &>a {
      display: flex;
      align-items: center;
      width: max-content;

      :nth-child(2) {
        margin-left: 1rem;
      }
    }

    &.first {
      font-size: 1.2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;


      .navbar__group___first__item span:nth-child(2) {
        font-size: 1.5rem;
        color: brown;
      }

      .navbar__group__second__item {
        display: flex;
        align-items: center;

        span {
          font-size: 1.5rem;
        }
      }
    }

    &.last {
      margin: 0 auto;
    }
  }

  &.open {
    visibility: 1;
    transform: translateX(0);
  }
}

@media screen and (min-width: 768px) {
  .navbar {
    top: 7rem;
    padding: 2rem 2rem 4rem;

  }
}

@media screen and (min-width: 1200px) {
  .navbar {
    top: 7rem;
    padding: 2rem 5rem 4rem;

  }
}